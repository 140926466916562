import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '@x/ecommerce/domain-client';
import { ContentAutocompleteDataSource, ContentRefTransformer } from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';

export interface ContentRefDialogData {
  refs: {
    locale: string;
    ref: string | null;
  }[];
  contentType: string;
}
export interface ContentRefDialogResult {
  refs: {
    locale: string;
    ref: string | null;
  }[];
}

@Component({
  selector: 'x-content-ref-dialog',
  templateUrl: './content-ref-dialog.component.html',
  styleUrls: ['./content-ref-dialog.component.scss'],
  providers: [ContentAutocompleteDataSource, ContentRefTransformer],
})
export class ContentRefDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialogRef<ContentRefDialogComponent, ContentRefDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: ContentRefDialogData,
    public datasource: ContentAutocompleteDataSource,
    public transformer: ContentRefTransformer,
    public contentService: ContentService,
  ) {}

  refs = new UntypedFormArray([]);
  form = new UntypedFormGroup({
    refs: this.refs,
  });

  ngOnInit(): void {
    this.data.refs.forEach((ref) =>
      this.refs.push(
        new UntypedFormGroup({
          ref: new UntypedFormControl(ref.ref),
          locale: new UntypedFormControl(ref.locale, []),
        }),
      ),
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  submit() {
    this.form.updateValueAndValidity();
    if (this.form.invalid) return;

    const refs = this.refs.controls.map((r) => {
      const ref = r.get('ref')?.value;
      return {
        locale: r.get('locale')?.value,
        ref: ref && ref.length > 0 ? ref : null,
      };
    });

    this.dialog.close({
      refs,
    });
  }
}
