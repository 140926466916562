import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ContentRefDialogComponent,
  ContentRefDialogData,
  ContentRefDialogResult,
} from '../components/content-ref-dialog/content-ref-dialog.component';

@Injectable()
export class ContentDialogService {
  constructor(private dialogs: MatDialog) {}

  openContentRefDialog(data: ContentRefDialogData) {
    return this.dialogs.open<
      ContentRefDialogComponent,
      ContentRefDialogData,
      ContentRefDialogResult
    >(ContentRefDialogComponent, {
      minWidth: '40vw',
      data,
    });
  }
}
